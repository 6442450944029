<template>
	<div>
		<e-table :tableCols="orderTableConf" :dataOrigin="dataOrigin">
		</e-table>
	</div>
</template>

<script>
import {orderTableConf} from '@data/mine'
export default {
	name: 'Order',
	data() {
		return {
			orderTableConf,
			dataOrigin: {
				data: [
				]
			}
		}
	},
	methods: {
		handleClick() {
		}
	}
}
</script>