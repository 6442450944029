export const orderTableConf = [
	{
		label: '订单类型',
		prop: 'account12',
	},
	{
		label: '订单编号',
		prop: 'account13',
	},
	{
		label: '订单名称',
		prop: 'account14',
	},
	{
		label: '实付金额',
		prop: 'account15',
	},
	{
		label: '支付方式',
		prop: 'account16',
	},
	{
		label: '创建时间',
		prop: 'account17',
	},
	{
		label: '状态',
		prop: 'account18',
	},
	{
		label: '操作',
		prop: 'account19',
	},
]

export const noteTableConf = [
	{
		label: '序号',
		prop: 'account12',
	},
	{
		label: '短信编号',
		prop: 'account13',
	},
	{
		label: '接收方客户名称',
		prop: 'account14',
		search: true,
		type: 'input'
	},
	{
		label: '接收方手机号码',
		prop: 'account15',
		search: true,
		type: 'input'
	},
	{
		label: '通知条件',
		prop: 'account16',
		search: true,
		type: 'select',
		options: []
	},
	{
		label: '内容',
		prop: 'account17',
	},
	{
		label: '状态',
		prop: 'account18',
	},
	{
		label: '拆分条数',
		prop: 'account181',
	},
	{
		label: '发送时间',
		prop: 'account182',
		search: true,
		type: 'daterange'
	},
	{
		label: '状态',
		prop: 'account19',
		search: true,
		type: 'select',
		options: []
	},
]